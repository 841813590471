import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import styled from 'styled-components';
import { Offline, Online } from 'react-detect-offline';
import { navigate } from 'gatsby-link';
import { Link } from 'gatsby';
import CryptoJS from 'crypto-js';

import trackEvent from '../components/analytics';

import Layout, {
  Player,
  ViewButtonPrevious,
  ViewButtonNext,
  ShareButtons,
} from '../components/layout';
import SEO from '../components/seo';
import Sound from '../audio/fr/voixoff_FR_59.mp3';
import Background from '../images/landscape-1.jpg';

const ViewArticle = styled.article`
  display: flex;
  flex: 1 0 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  max-width: 100%;
  text-align: center;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  > * {
    animation: bounceIn;
    animation-duration: 1s;
  }
`;

const ViewFormArticle = styled.form`
  display: flex;
  flex: 0 1 auto;
  max-width: 100%;
  flex-flow: row wrap;
  align-items: center;
  justify-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 0 6rem;

  > * {
    margin: 0.2rem 0;
  }
`;

class Part4_Success extends Component {
  constructor(props) {
    super(props);
    this.state = { name: '', phone: '' };
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    if (typeof window !== `undefined`) {
      !localStorage.getItem('fc4567y') &&
        localStorage.setItem(
          'fc4567y',
          CryptoJS.AES.encrypt(
            JSON.stringify(0),
            'dfsf5yu7*&hfios7f8s6756ughj',
          ).toString(),
        );
    }
  }

  onChangeName = (e) => {
    e.preventDefault();
    this.setState({ name: e.target.value });
  };

  onChangePhone = (e) => {
    e.preventDefault();
    this.setState({ phone: e.target.value });
  };

  addUser = () => {
    var myHeaders = new Headers();
    myHeaders.append('x-api-key', process.env.GATSBY_APPSYNC_KEY);
    myHeaders.append('Content-Type', 'application/json');

    var graphqlQuery = JSON.stringify({
      query:
        'mutation createPerson($createpersoninput: CreatePersonInput!) {createPerson(input: $createpersoninput) {phone name}}',
      variables: {
        createpersoninput: { name: this.state.name, phone: this.state.phone },
      },
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphqlQuery,
      redirect: 'follow',
    };

    fetch(process.env.GATSBY_APPSYNC_ENDPOINT, requestOptions)
      .then((response) => response.text())
      // .then((result) => console.log(result))
      // .catch((error) => console.log('error', error))
      .then(() => {
        if (typeof window !== `undefined`) {
          var bytes = CryptoJS.AES.decrypt(
            localStorage.getItem('fc4567y'),
            'dfsf5yu7*&hfios7f8s6756ughj',
          );
          var Attempt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          localStorage.setItem(
            'fc4567y',
            CryptoJS.AES.encrypt(
              JSON.stringify(++Attempt),
              'dfsf5yu7*&hfios7f8s6756ughj',
            ).toString(),
          );
        }
      })
      .then(navigate('/fr/end'));
  };

  render() {
    if (typeof window !== `undefined`) {
      var bytes = CryptoJS.AES.decrypt(
        localStorage.getItem('fc4567y'),
        'dfsf5yu7*&hfios7f8s6756ughj',
      );
      var AttemptCheck = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } else {
      AttemptCheck = 0;
    }

    return (
      <Layout location={typeof window !== 'undefined' ? location : null}>
        <SEO pathname="/fr/part-4-success" />

        <ViewArticle>
          <h1>Bravo !</h1>
          <ViewFormArticle>
            <Input
              type="text"
              name="name"
              onChange={this.onChangeName}
              placeholder="Ton nom"
              required
            />
            <Input
              type="text"
              name="phone"
              onChange={this.onChangePhone}
              placeholder="Ton téléphone"
              required
            />
            <Online>
              {AttemptCheck < 10 ? (
                <Button
                  color="success"
                  disabled={
                    this.state.name === '' || this.state.phone === '' ? true : false
                  }
                  onClick={this.addUser}
                >
                  <i className="fa fa-check" /> Valider
                </Button>
              ) : (
                <Button disabled>
                  <i className="fa fa-times" /> Valider
                </Button>
              )}
            </Online>
            <Offline>
              <strong>Réseau indisponible</strong>
              <br />
              <Button disabled>
                <i className="fa fa-times" /> Valider
              </Button>
            </Offline>
          </ViewFormArticle>
          <ShareButtons />
        </ViewArticle>
        <Link to="/fr/part-4-quizz-form">
          <ViewButtonPrevious color="warning">
            <i className="fa fa-chevron-left fa" />
          </ViewButtonPrevious>
        </Link>
        <Link to="/fr/end">
          <ViewButtonNext
            color="warning"
            onClick={() => {
              trackEvent('Success Page Part 4', 'User finished the module', 'yes');
            }}
          >
            <i className="fa fa-chevron-right fa" />
          </ViewButtonNext>
        </Link>
        <Player sound={Sound} />
      </Layout>
    );
  }
}

export default Part4_Success;
